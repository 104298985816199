import { setLoader } from '@/shared/store/actions/default'
import store from '@/shared/store/store'
import axios, {
	AxiosError,
	AxiosResponse,
	InternalAxiosRequestConfig
} from 'axios'
import Cookies from 'js-cookie'

export const REACT_APP_API_URL =
	process.env.REACT_APP_API_URL || 'https://api.lebaoba.fun/'

const axiosInstance = axios.create({
	baseURL: REACT_APP_API_URL
})

const interceptorsRequestSuccess = (
	config: InternalAxiosRequestConfig<any>
) => {
	const accessToken =
		localStorage.getItem('access') || Cookies.get('accessToken')
	if (config.headers && accessToken)
		config.headers.Authorization = `Bearer ${accessToken}`

	return config
}

const interceptorsRequestFailed = (error: AxiosError<any>) => {
	return error.response
}

// Начало запроса
axiosInstance.interceptors.request.use(
	interceptorsRequestSuccess,
	interceptorsRequestFailed
)

const interceptorsResponseSuccess = (response: AxiosResponse<any>) => {
	// @ts-ignore
	store.dispatch(setLoader(false))

	return response
}
const interceptorsResponseFailed = async (error: AxiosError) => {
	const originalConfig = { ...error.config, _retry: false }
	const refreshToken = localStorage.getItem('refresh')

	if (
		originalConfig &&
		originalConfig.url !== 'api/security/user/access' &&
		error.response
	) {
		if (
			(error.response.status === 401 || error.response.status === 403) &&
			!originalConfig._retry
		) {
			originalConfig._retry = true

			try {
				await axios
					.get(`${REACT_APP_API_URL}api/security/user/refresh`, {
						headers: { Authorization: `Bearer ${refreshToken}` }
					})
					.then(response => {
						const access = response.data.access
						const refresh = response.data.refresh
						localStorage.setItem('refresh', refresh)
						originalConfig.headers!['Authorization'] = `Bearer ${access}`
						store.dispatch({
							type: 'LOGIN_SUCCESS',
							value: response.data
						})
					})
				return await axios(originalConfig)
			} catch (_error: any) {
				if (_error.response.status === 404) {
					store.dispatch({
						type: 'LOGOUT',
						value: {}
					})
					return Promise.reject(_error)
				}

				return Promise.reject(_error)
			}
		}

		if (error.response.status === 422) {
			store.dispatch({
				type: 'SET_MESSAGE',
				value: {
					// @ts-ignore
					message: error.response.data.detail,
					type: 'error'
				}
			})
		}
	}
	// @ts-ignore
	store.dispatch(setLoader(false))
	// вернуть сообщение об ошибке, возвращенном интерфейсом
	return Promise.reject(error)
}

axiosInstance.interceptors.response.use(
	interceptorsResponseSuccess,
	interceptorsResponseFailed
)

export default axiosInstance
